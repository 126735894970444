<template>
  <a-row class="haya-panels mb-20" :gutter="20">

  </a-row>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "BillsToPayReportDataPanels",
  props: {
    contractInlineReport: Object,
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {},
  created() {

  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
